export function toggleClassForInput($element) {
  if ($element.val() === '') {
    $element.removeClass('float-label')
  } else {
    $element.addClass('float-label')
  }
}

export function toggleClassForDropdown($element) {
  if (!$element.prop('tagName') === 'DIV' && !$element.prop('tagName') === 'SELECT') return
  if ($element.prop('tagName') === 'SELECT') $element = $element.parent('.dropdown')

  const value = $element.dropdown('get value')
  const select = $element.find('select')[0]

  if (
    (value === '' && !select.options[select.selectedIndex].text) ||
    (value instanceof Array && value.length === 0) ||
    value === null
  ) {
    $element.removeClass('float-label')
  } else {
    $element.addClass('float-label').addClass('float-label').addClass('float-label')
  }
}
