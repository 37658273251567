import 'fomantic-ui-dropdown/dropdown'
import 'semantic-ui-transition/transition'

import { Controller } from '@hotwired/stimulus'
import { toggleClassForDropdown } from '@/utils/fields'

export default class extends Controller {
  static values = {
    config: { type: Object, default: {} }
  }

  connect() {
    this.$element = $(this.element)
    this.$element.dropdown(this.config())
    if (this.$element.hasClass('allow-additions')) {
      this.$element.children('.search').keydown(event => {
        if (event.keyCode === 13) event.preventDefault()
      })
    }
  }

  config() {
    const baseConfig = {
      forceSelection: false,
      fullTextSearch: 'exact',
      ignoreDiacritics: true,
      ignoreCase: true,
      context: 'body',
      match: 'text',
      message: {
        addResult: 'Ajouter <b>{term}</b>',
        count: '{count} sélectionnés',
        maxSelections: '{maxCount} sélections maximum',
        noResults: 'Aucun résultat trouvé'
      },
      className: {
        dropdown: 'dropdown',
        label: 'label label-default'
      },
      selector: {
        dropdown: '.dropdown'
      },
      onChange(value, text, $choice) {
        toggleClassForDropdown($(this))
        if ($(this).hasClass('search')) document.activeElement.blur()
      }
    }

    return {
      ...baseConfig,
      ...(this.$element.hasClass('dropdown-with-blank') ? { placeholder: false } : {}),
      ...(this.$element.hasClass('allow-additions') ? { allowAdditions: true, hideAdditions: false } : {}),
      ...this.configValue
    }
  }
}
