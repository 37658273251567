window.turboScroll = {}

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('*[data-turbo-scroll="false"]').forEach(element => {
    element.addEventListener('click', () => {
      window.turboScroll.top = document.scrollingElement.scrollTop
    })
    element.addEventListener('submit', () => {
      window.turboScroll.top = document.scrollingElement.scrollTop
    })
  })

  window.turboScroll = {}
})

document.addEventListener('turbo:render', () => {
  if (window.turboScroll.top) {
    document.scrollingElement.scrollTo(0, window.turboScroll.top)
  }
})
