import { application } from './application'
import StimulusControllerResolver, { createViteGlobResolver } from 'stimulus-controller-resolver'
import DropdownController from './dropdown_controller'

application.register('dropdown-controller', DropdownController)


StimulusControllerResolver.install(application, createViteGlobResolver(
  import.meta.glob('./**/*_controller.js'),
  import.meta.glob('./../../components/**/*_controller.js')
))
