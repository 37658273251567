import '@hotwired/turbo-rails'
import '../initializers'

import Rails from '@rails/ujs'

// Load Stimulus controllers
import '../controllers'

import '../lib/turbo-persist-scroll'

// Start UJS
window.Rails = Rails
if (!window._rails_loaded) Rails.start()
