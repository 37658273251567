import { computePosition, flip, offset, shift } from '@floating-ui/dom'

export default class Tooltip {
  constructor(target, content, options = {}) {
    this.target = target
    this.content = content
    this.placement = options.placement || 'bottom'
    this.multiline = options.multiline ?? false
    this.element = this.createElement()
  }

  createElement() {
    const element = document.createElement('div')
    element.classList.add(
      'hidden',
      'bg-neutral-600',
      'text-white',
      'px-2',
      'py-1',
      'rounded',
      'text-sm',
      'text-center',
      'max-w-60',
      'w-max-content',
      'absolute',
      'z-[920]',
      'pointer-events-none'
    )
    if (this.multiline) element.classList.add('whitespace-pre-line', 'max-w-[300px]')
    element.innerHTML = this.content
    element.role = 'tooltip'

    document.body.appendChild(element)

    return element
  }

  show() {
    this.element.classList.remove('hidden')
    this.update()
  }

  hide() {
    this.element.classList.add('hidden')
  }

  update() {
    computePosition(this.target, this.element, {
      placement: this.placement,
      middleware: [offset(6), flip(), shift({ padding: 5 })]
    }).then(({ x, y }) => {
      Object.assign(this.element.style, {
        left: `${x}px`,
        top: `${y}px`
      })
    })
  }

  destroy() {
    this.element.remove()
  }
}
