import { Turbo } from '@hotwired/turbo-rails'

// <turbo-stream action="replace-temporarly" target="SOME_ELEMENT_ID">
//  <template>
//    Content to replace the element with
//  </template>
// </turbo-stream>
Turbo.StreamActions['replace-temporarly'] = function () {
  const element = document.getElementById(this.target)
  const initialContent = element.innerHTML

  element.innerHTML = ''
  element.append(this.querySelector('template').content)

  setTimeout(() => {
    element.innerHTML = initialContent
  }, 1500)
}

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target)
}
